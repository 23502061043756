<template>
  <v-footer
    dark
    color="secondary"
    :class="{ 'd-none': $route.path == '/home-two' }"
  >
    <v-container>
      <v-row>
        <v-col cols="12" xl="4" lg="4" md="4" sm="6">
          <v-avatar width="150" height="100" tile class="mb-5">
            <img src="@/assets/images/MVO.png" alt="" />
          </v-avatar>
          <p class="footer-text mb-5 white--text">
            Mutue Vendas Online é uma Plataforma que permite comprar produtos
            online e entregamos a tua mercadoria Onde e Quando Quiser.
          </p>
          <div class="d-flex flex-sm-nowrap flex-wrap">
            <a
              href="https://play.google.com/store/apps/details?id=com.mutuevendasonline.mutue&pli=1"
              class="me-3 mb-3"
              target="_blank"
            >
              <v-img
                title="Aceder a Playstore
              "
                max-width="130"
                src="@/assets/images/app-store/google-play.png"
              />
            </a>
            <a
              href="https://apps.apple.com/app/mutue-vendas-online/id6475088342"
            >
              <v-img
                title="Aceder a AppStore "
                max-width="130"
                src="@/assets/images/app-store/app-store.png"
              />
            </a>
          </div>
        </v-col>

        <v-col cols="12" xl="2" lg="2" md="4" sm="6">
          <h2 class="mb-5">Sobre Nós</h2>
          <router-link
            :to="{ name: 'LojasIndex' }"
            class="text-decoration-none blue-grey--text darken-1"
          >
            <!-- <div class="my-1">

            </div> -->
          </router-link>
          <!-- <div class="my-1">
            <a href="#" class="text-decoration-none footer-link-hover"
              >Nossas Lojas</a
            >
          </div> -->
          <div class="my-1">
            <a
              href="#"
              class="text-decoration-none white--text footer-link-hover"
              @click="dialogTermosDeUso = true"
              >Termos de Uso</a
            >
          </div>
          <div class="my-1">
            <a
              href="#"
              class="text-decoration-none white--text footer-link-hover"
              @click="dialogPoliticasPrivacidade = true"
              >Políticas de Privacidade</a
            >
          </div>
        </v-col>
        <v-col class="name" cols="12" xl="3" lg="3" md="4" sm="6">
          <h2 class="mb-5">Links Rápidos</h2>
          <div class="my-1">
            <a
              btn
              class="text-decoration-none white--text"
              @click="gotoQuemSomos()"
            >
              Quem Somos</a
            >
          </div>
          <div class="my-1">
            <a btn class="text-decoration-none white--text" @click="gotoFaqs()">
              Perguntas Frequentes</a
            >
          </div>

          <div class="my-1">
            <a
              btn
              class="text-decoration-none white--text"
              @click="gotoContactos()"
              >Fale Connosco</a
            >
          </div>
          <!-- <div class="my-1">
            <a btn class="text-decoration-none white--text" @click="gotoFaqs()">Reembolsos & Devoluções</a>
          </div> -->

          <!-- <div v-for="(n, index) in customerCare" :key="index" class="my-1">
            <a href="#" class="text-decoration-none footer-link-hover">{{
              n
            }}</a>
          </div> -->
        </v-col>
        <v-col cols="12" xl="3" lg="3" md="4" sm="6">
          <h2 class="mb-5">Localização</h2>
          <!-- <v-btn color="white" target="_blank" href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3941.474233522842!2d13.345936775814634!3d-8.928371491703588!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1a51f70dff02e19d%3A0x3ba5548c308396e3!2sMutue%20-%20Loja%20de%20Inform%C3%A1tica!5e0!3m2!1spt-PT!2sao!4v1700127803762!5m2!1spt-PT!2sao"> <v-icon> mdi-map-marker-radius</v-icon> Viana, Rua do Ginga Shopping, Junto a casa dos
            Frescos.</v-btn> -->
          <div class="googlemap">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3941.474233522842!2d13.345936775814634!3d-8.928371491703588!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1a51f70dff02e19d%3A0x3ba5548c308396e3!2sMutue%20-%20Loja%20de%20Inform%C3%A1tica!5e0!3m2!1spt-PT!2sao!4v1700127803762!5m2!1spt-PT!2sao"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            />
          </div>
          <!-- <p link class="footer-text white--text">
            <v-icon
              link
              btn
              target="_blank"
              href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3941.474233522842!2d13.345936775814634!3d-8.928371491703588!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1a51f70dff02e19d%3A0x3ba5548c308396e3!2sMutue%20-%20Loja%20de%20Inform%C3%A1tica!5e0!3m2!1spt-PT!2sao!4v1700127803762!5m2!1spt-PT!2sao"
            >
              mdi-map-marker-radius
            </v-icon>
            Viana, Rua do Ginga Shopping, Junto a casa dos Frescos.
          </p> -->
          <p link @click="sendMail()" class="footer-text white--text">
            <v-icon btn @click="sendMail()">mdi-email</v-icon>
            loja@mutue.net
          </p>
          <p link @click="makeCalling()" class="footer-text mb-5 white--text">
            <v-icon size="sm" btn @click="makeCalling()"> mdi-phone </v-icon>
            +244 934 66 00 03
          </p>

          <!-- <v-btn
            v-for="icon in icons"
            :key="icon"
            class="me-2 white--text"
            elevation="0"
            x-small
            fab
            dark
            color="secondary darken-1"
          >
            <v-icon small size="24px">
              {{ icon }}
            </v-icon>
          </v-btn> -->

          <v-btn
            target="_blank"
            href="https://api.whatsapp.com/send?1=pt_BR&phone=244922969192&text=Ol%C3%A1!%20Tudo%20bem?%20Gostaria%20de%20saber%20como%20funciona%20o%20vosso%20Aplicativo."
            class="me-2 white--text"
            elevation="0"
            x-small
            fab
            dark
            color="secondary darken-1"
          >
            <v-icon small size="24px"> mdi-whatsapp </v-icon>
          </v-btn>

          <v-btn
            target="_blank"
            href="https://www.facebook.com/people/Mutue-Solu%C3%A7%C3%B5es/100093098367911/"
            class="me-2 white--text"
            elevation="0"
            x-small
            fab
            dark
            color="secondary darken-1"
          >
            <v-icon small size="24px"> mdi-facebook </v-icon>
          </v-btn>
          <v-btn
            target="_blank"
            href="https://www.instagram.com/mutue.sti/"
            class="me-2 white--text"
            elevation="0"
            x-small
            fab
            dark
            color="secondary darken-1"
          >
            <v-icon small size="24px"> mdi-instagram </v-icon>
          </v-btn>

          <!-- <v-btn target="_blank" href="#" class="me-2 white--text" elevation="0" x-small fab dark color="secondary darken-1">
            <v-icon small size="24px">
              mdi-youtube
            </v-icon>
          </v-btn> -->
        </v-col>
      </v-row>
      <!-- <v-row class="text-center">
        <v-col cols="12">
          <h1>COPYRIGHT</h1>
        </v-col>
      </v-row> -->
    </v-container>

    <v-dialog v-model="dialogTermosDeUso" scrollable max-width="850">
      <v-card>
        <v-card-title class="secondary white--text">
          Termos de Uso
          <v-spacer />
          <v-btn
            icon
            text
            elevation="1"
            small
            @click="dialogTermosDeUso = false"
          >
            <v-icon class="secondary white--text"> mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="mt-0" color="primary" />
        <v-card-text class="text-justify">
          <br />
          <v-spacer />
          <b>1. Condições De Uso</b>
          <br />
          Estes termos regem a utilização da Plataforma Mutue Vendas Online,
          seus direitos e obrigações enquanto usuário, bem como as condições de
          comercialização de produtos e serviços dentro da Plataforma, excepto
          quando informado expressamente que se aplicam outros termos
          específicos. A Mutue Vendas Online é um ambiente de negociação online
          em que indivíduos e empresas têm a oportunidade de anunciar o que
          desejam comprar ou vender, e se candidatarem a oferecer os produtos ou
          serviços solicitados na Plataforma. Neste ambiente, os resultados das
          negociações são da inteira responsabilidade dos intervenientes,
          ficando ao cargo deles a decisão final de comprarem ou venderem
          determinado produto ou serviço. Nesta plataforma, cada autônomo tem o
          direito de assumir duas posições, sendo uma de Empregador e a outra de
          Empregado, alterando as suas posições consoante for a negociação.
          <br />
          <br />
          <b>2. Comunicações e outros conteúdos</b>
          <br />
          Você pode postar trabalhos, enviar comentários, sugestões, questões e
          ou outras informações, desde que o conteúdo não seja ilegal, obsceno,
          ameaçador, difamatório, invasivo da privacidade, violação de direitos
          de propriedade intelectual (incluindo direitos de publicidade) ou de
          outra forma prejudicial a terceiros ou censurável, e não consiste ou
          contém vírus de software, campanha política, solicitação comercial.
          Por outra, ficam também proibidos o uso de endereços de e-mail falsos,
          se passar por qualquer pessoa ou entidade ou induzir em erro quanto à
          origem de um cartão ou outro conteúdo. A Mutue Vendas Online se
          reserva o direito (mas não a obrigação) de remover ou editar tal
          conteúdo. Os autônomos dispõe de uma opção de reportar os anúncios que
          considerem não associados a cultura que se está a firmar neste espaço,
          ficando a cargo da equipe de suporte a remoção destes anúncios.
          <br />
          <br />
          <b>3. Reclamações de propriedade intelectual</b>
          <br />
          A Mutue Vendas Online respeita a propriedade intelectual dos outros.
          Se os seus direitos de propriedade intelectual estão sendo violados e
          usados por outrem na nossa Plataforma, envie um e-mail para
          loja@mutue.net, com ASSUNTO: RECLAMAÇÃO - REIVINDICAÇÕES DE VIOLAÇÃO
          DE DIREITOS AUTORAIS, e entraremos em contacto consigo em menos de 48H
          para apurar a veracidade do mesmo. Caso se comprove a violação dos
          direitos de propriedade intelectual, o anúncio é removido da
          Plataforma. Por outra, não assumimos qualquer responsabilidade da
          venda de produtos, projectos ou serviços em que se prove que o
          vendedor não era o legitimo proprietário do mesmo.
          <br />
          <br />

          <b>4. Renegociações</b>
          <br />
          As renegociações decorrem do facto de determinadas cláusulas de um
          anúncio precisarem ser alteradas com o passar do tempo, como único
          meio de salvaguardar o interesse das partes. A Mutue Vendas Online não
          se responsabiliza pelas renegociações, ao menos que sejam negociações
          cobertas pelos serviços de seguro ou intermediação dA Mutue Vendas
          Online.
          <br />
          <br />
          <b>5. Descrições dos produtos ou serviços</b>
          <br />
          <br />
          Na Mutue Vendas Online as compras são realizadas através de preços
          pré-fixados já com IVA incluido. O preço é tido como elemento chave de
          qualquer produto não podendo existir anúncios sem um preço.
          <br />
          <br />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary darken-1"
            text
            @click="dialogTermosDeUso = false"
          >
            OK
          </v-btn>
          <v-spacer class="hidden-sm-and-up" />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogPoliticasPrivacidade" scrollable max-width="850">
      <v-card>
        <v-card-title class="secondary white--text">
          Políticas de Privacidade
          <v-spacer />
          <v-btn
            icon
            text
            elevation="1"
            small
            @click="dialogPoliticasPrivacidade = false"
          >
            <v-icon class="secondary white--text"> mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="mt-0" color="primary" />
        <v-card-text class="text-justify">
          <br />
          <v-spacer />
          Esta Política de Privacidade descreve como www.loja.mutue.net coleta,
          usa e protege as informações que você fornece ao utilizar nosso site
          www.loja.mutue.net.
          <br />
          <br />
          <b>1. Informações Coletadas</b>
          <br />
          Ao utilizar nosso site, podemos coletar as seguintes informações
          pessoais: Nome Endereço de e-mail Endereço residencial Informações de
          pagamento Também podemos coletar informações não pessoais, como dados
          demográficos, preferências e interesses, para melhorar nossos produtos
          e serviços.

          <br />
          <br />

          <b> 2. Informações Coletadas </b>
          <br />
          Ao utilizar nosso site, podemos coletar as seguintes informações
          pessoais: Nome Endereço de e-mail Endereço residencial Informações de
          pagamento Também podemos coletar informações não pessoais, como dados
          demográficos, preferências e interesses, para melhorar nossos produtos
          e serviços.
          <br /><br />
          <b>3. Uso das Informações </b>
          <br />
          As informações coletadas são usadas para:
          <br />
          Processar pedidos e pagamentos Entregar produtos e serviços
          Personalizar sua experiência de compra Enviar e-mails promocionais
          sobre novos produtos, ofertas especiais ou outras informações que
          possam interessar a você (você pode optar por não receber esses
          e-mails a qualquer momento) Proteção das Informações Nós nos
          comprometemos a garantir a segurança e privacidade das suas
          informações. Implementamos medidas de segurança adequadas para
          proteger contra acesso não autorizado, alteração, divulgação ou
          destruição dessas informações.
          <br />
          <br />
          <b>4. Cookies </b>
          <br />
          Nosso site pode usar cookies para melhorar sua experiência de usuário.
          Você pode optar por aceitar ou recusar esses cookies modificando as
          configurações do seu navegador.

          <br />
          <br />
          <b> 5. Divulgação a Terceiros </b>
          <br />
          Não vendemos, comercializamos ou transferimos suas informações
          pessoais para terceiros sem seu consentimento, exceto quando
          necessário para fornecer um serviço solicitado por você (por exemplo,
          entrega de produtos).
          <br />
          <br />
          <b> 6. Links para Sites Externos </b>
          <br />
          Nosso site pode conter links para sites externos. Não somos
          responsáveis pelas práticas de privacidade desses sites. Recomendamos
          que você leia as políticas de privacidade deles.
          <br />
          <br />

          <b> 7. Seus Direitos </b>
          <br />
          Você tem o direito de acessar, corrigir ou apagar suas informações
          pessoais a qualquer momento. Se desejar exercer esses direitos ou
          tiver dúvidas sobre nossa política de privacidade, entre em contato
          conosco através das informações fornecidas abaixo.
          <br />
          <br />
          <b> 8. Alterações nesta Política de Privacidade </b>

          <br />
          Reservamos o direito de atualizar esta política a qualquer momento.
          Recomendamos que você revise periodicamente para estar ciente de
          quaisquer alterações. Ao utilizar nosso site, você concorda com esta
          política de privacidade. Contato Se você tiver alguma dúvida sobre
          esta Política de Privacidade, entre em contato conosco: <br />
          <b> Mutue Loja Online </b> <br />
          <b> +244 934 66 00 03 </b> <br />
          <b> loja@mutue.net </b> <br />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary darken-1"
            text
            @click="dialogPoliticasPrivacidade = false"
          >
            OK
          </v-btn>
          <v-spacer class="hidden-sm-and-up" />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-footer>
</template>

<script>
export default {
  name: "Footer",

  data: () => ({
    dialogPoliticasPrivacidade: false,
    dialogTermosDeUso: false,
    icons: [
      "mdi-facebook",
      "mdi-twitter",
      "mdi-youtube",
      "mdi-google",
      "mdi-instagram",
    ],
    aboutUs: [
      "Nossas Lojas",
      "Nossos Objectivos",
      // "Termos & Condições",
      // "Políticas de Privacidade",
    ],
    customerCare: [
      "Centro de Ajuda",
      "Como Comprar",
      "Rastrear minha Encomenda",
      "Reembolsos & Devoluções",
    ],
  }),
  methods: {
    sendMail() {
      let email = "loja@mutue.net";
      window.open("mailto:" + email);
    },
    makeCalling() {
      let telefone = "934660003";
      window.open("tel:" + telefone);
    },
    gotoFaqs() {
      this.$router.push({
        name: "FAQS",
      });
    },
    gotoQuemSomos() {
      this.$router.push({
        name: "QuemSomos",
      });
    },

    gotoContactos() {
      this.$router.push({
        name: "ContactosIndex",
      });
    },
  },
};
</script>

<style lang="scss">
a.footer-link-hover {
  color: #bdbdbd !important;

  &:hover {
    color: #fff !important;
  }
}

.v-footer {
  padding-top: 5rem;
  padding-bottom: 5rem;
}
</style>
